import TaskEntity from '../../db/entities/task/TaskEntity';

export const sortByReminder = (tasks: TaskEntity[]) => {
  const now = Date.now();

  return [...tasks].sort((nextTask, prevTask) => {
    if (nextTask.reminder?.date) {
      if (!prevTask.reminder) {
        return -1;
      }

      if (nextTask.reminder.date < now) {
        if (nextTask.status === 'new') {
          if (prevTask.reminder?.date < now && prevTask.status === 'new') {
            if (nextTask.reminder.date > prevTask.reminder.date) {
              return 1;
            } else {
              return -1;
            }
          } else {
            return -1;
          }
        } else if (nextTask.status === 'completed') {
          return 1;
        }
      } else {
        if (nextTask.reminder.date > prevTask.reminder.date) {
          return -1;
        } else {
          return 1;
        }
      }
    } else {
      if (prevTask.reminder) {
        return 1;
      }

      if (nextTask.dueDate) {
        if (!prevTask.dueDate) {
          return -1;
        } else {
          if (nextTask.dueDate > prevTask.dueDate) {
            return -1;
          } else {
            return 1;
          }
        }
      } else {
        if (prevTask.dueDate) {
          return 1;
        } else {
          if (nextTask.createdAt > prevTask.createdAt) {
            return -1;
          } else {
            return 1;
          }
        }
      }
    }
  });
};

export const sortByDueDate = (tasks: TaskEntity[]) => {
  const now = Date.now();

  return [...tasks].sort((nextTask, prevTask) => {
    if (nextTask.status === 'new') {
      if (prevTask.status === 'completed') {
        return -1;
      }

      if (nextTask.dueDate) {
        if (!prevTask.dueDate) {
          return -1;
        }

        if (nextTask.dueDate < now) {
          if (prevTask.dueDate > now) {
            return -1;
          }

          if (nextTask.dueDate > prevTask.dueDate) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (nextTask.dueDate > prevTask.dueDate) {
            return 1;
          } else {
            return -1;
          }
        }
      } else {
        if (prevTask.dueDate) {
          return 1;
        } else {
          if (nextTask.createdAt > prevTask.createdAt) {
            return -1;
          } else {
            return 1;
          }
        }
      }
    } else if (nextTask.status === 'completed') {
      if (nextTask.createdAt > prevTask.createdAt) {
        return -1;
      } else {
        return 1;
      }
    }
  });
};

export const sortByImportancy = (tasks: TaskEntity[]) => {
  return [...tasks].sort((nextTask, prevTask) => {
    if (nextTask.important) {
      if (!prevTask.important) {
        return -1;
      } else {
        return prevTask.createdAt - nextTask.createdAt;
      }
    } else {
      if (prevTask.important) {
        return 1;
      } else {
        return prevTask.createdAt - nextTask.createdAt;
      }
    }
  });
};

export const filterByCompleted = (tasks: TaskEntity[]) => {
  return [...tasks]
    .filter((t) => t.status === 'completed')
    .sort((prevTask, nextTask) => nextTask.updatedAt - prevTask.updatedAt);
};
