import clsx from 'clsx';
import Avatar from '../../Avatar';
import styles from './MembersDialog.module.css';
import { Avatar as AvatarT } from './index';
const MembersDialog = ({ member, disabled = false }: { member: AvatarT; disabled?: boolean }) => {
  return (
    <div className={clsx(styles.item, disabled && styles.disabledItem)}>
      <div className={styles.avatar}>
        <Avatar size={24} thumbnail={member.thumbnail} name={member.fullName || member.email} />
      </div>
      <span className={styles.name}>{member.fullName || member.email}</span>
    </div>
  );
};
export default MembersDialog;
