import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.scss';
import { IButtonWhiteBorderedProps } from '../Buttons.types';
import AddUserIcon from 'icons/invite-teammember.svg';

const ButtonWhiteBordered: React.FC<IButtonWhiteBorderedProps> = (props) => {
  const { icon, text, className, variant, ...rest } = props;
  let resultIcon: React.ReactNode;

  switch (variant) {
    case 'add-person': {
      resultIcon = <AddUserIcon />;
      break;
    }

    default: {
      resultIcon = icon;
      break;
    }
  }

  return (
    <button {...rest} className={clsx(styles.Button, className)}>
      {resultIcon}
      <span>{text}</span>
    </button>
  );
};

export default ButtonWhiteBordered;
