import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';

import { MessageType } from '../../../../components/UI/snackbar/providers/SnackBarProvider';
import IconCheck from 'public/images/icons/check.svg';
import { useAppDispatch } from '../../../../app/hooks';
import { patchTaskOptimistic } from '../../../../slices/tasksSlice/tasksSlice';

import styles from './StatusSwitcher.module.css';
import { trackEvent } from 'src/analytics/amplitude';
import TaskEntity from 'src/db/entities/task/TaskEntity';

export default function StatusSwitcher({
  id,
  status,
  disabled = false,
  onUpdateStart,
  onUpdateEnd,
  className,
}: {
  id: TaskEntity['id'];
  status: TaskEntity['status'];
  disabled?: boolean;
  onUpdateStart?: () => void;
  onUpdateEnd?: () => void;
  className?: string;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    try {
      if (onUpdateStart) {
        onUpdateStart();
      }

      const nextStatus = status === 'new' ? 'completed' : 'new';
      await dispatch(
        patchTaskOptimistic({
          id,
          data: { status: nextStatus, updatedAt: Date.now() },
        })
      ).unwrap();

      if (nextStatus === 'completed') {
        trackEvent('Task_completed');
        enqueueSnackbar({
          message: t('duplicates:Task_completed_message'),
          variant: MessageType.Success,
        });
      }
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({ message, variant: MessageType.Error });
    } finally {
      if (onUpdateEnd) {
        onUpdateEnd();
      }
    }
  };

  return (
    <div className={clsx(className, styles.wrapper)}>
      <button
        disabled={disabled}
        type="button"
        onClick={handleClick}
        className={clsx(styles.button, status === 'completed' && styles.completed)}
      >
        <IconCheck />
      </button>
    </div>
  );
}
