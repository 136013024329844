import { Popover, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import styles from './MembersDialog.module.css';
import MembersDialogLabel from './MembersDialogLabel';
import { ChangeEvent, useEffect, useState } from 'react';
export type Avatar = {
  email: string;
  fullName: string | null;
  thumbnail: string | null;
};
export type MembersDialogProps = {
  members: Avatar[];
  anchorEl: HTMLElement;
  selected: string[];
  onClose: () => void;
  onChange: ({ type, email }: { type: 'add' | 'remove'; email: string }) => void;
};

const MembersDialog = ({ members, anchorEl, onClose, selected, onChange }: MembersDialogProps) => {
  const [copyMembers, setCopyMembers] = useState<Avatar[]>(members);

  const handleClose = () => {
    onClose();
    setCopyMembers(members);
  };
  useEffect(() => {
    setCopyMembers(members);
  }, [members]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const type = e.target.checked ? 'add' : 'remove';
    onChange({ type, email: e.target.name });
  };
  const open = !!anchorEl;

  return (
    <Popover
      open={open}
      classes={{ paper: styles.container }}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <FormControl component="fieldset">
        <FormGroup>
          {copyMembers?.map((member) => {
            return (
              <FormControlLabel
                labelPlacement="start"
                key={member.email}
                className={styles.contactContainer}
                classes={{ label: styles.label }}
                control={
                  <Checkbox
                    classes={{ checked: styles.checked, root: styles.checkbox }}
                    checked={selected.includes(member.email)}
                    onChange={handleChange}
                    name={member.email}
                  />
                }
                label={<MembersDialogLabel member={member} />}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Popover>
  );
};

export default MembersDialog;
