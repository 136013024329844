import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';
import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { appRoutes } from 'src/utils/constants';

import styles from './styles.module.scss';

const BlackStrip = () => {
  const { t } = useTranslation('billing', { keyPrefix: 'promos' });

  const { subscription } = useCurrentSubscription();
  const { currentUser } = useCurrentUser();

  const {
    isUserOnFreePlan,
    isUserOnFreeTrial,
    isActive,
    daysLeft,
    isPaymentFailed,
    isCanceledAndExpired,
  } = subscription;
  const { isAdmin } = currentUser;

  let text = null;

  const updatedNowLinkBasedOnPermissions = isAdmin ? (
    <Link href={appRoutes.PLANS_PAGE}>{t('upgradeNow')}</Link>
  ) : (
    <>{t('contactAdmin')}</>
  );

  const fixNowLinkBasedOnPermissions = isAdmin ? (
    <span className={styles.fixPaymentLink}>
      <Link href={appRoutes.SUBSCRIPTION_PAGE}>{t('fixNow')}</Link>
    </span>
  ) : (
    <>{t('contactAdmin')}</>
  );

  if (!isActive) {
    text = (
      <>
        {t('subExpired')} {updatedNowLinkBasedOnPermissions}
      </>
    );
  }

  if (isUserOnFreeTrial) {
    if (isActive) {
      if (daysLeft > 1) {
        text = (
          <>
            {t('freeTrialDaysLeft', { daysLeft })} {updatedNowLinkBasedOnPermissions}
          </>
        );
      }
      if (daysLeft === 1) {
        text = (
          <>
            {t('freeTrialDayLeft')} {updatedNowLinkBasedOnPermissions}
          </>
        );
      }
    } else {
      text = (
        <>
          {t('freeTrialIsEnded')} {updatedNowLinkBasedOnPermissions}
        </>
      );
    }
  }

  if (isUserOnFreePlan) {
    text = (
      <>
        {t('onFreePlan')} {updatedNowLinkBasedOnPermissions}
      </>
    );
  }

  if (isPaymentFailed) {
    text = (
      <>
        {t('issueWithLastPayment')} {fixNowLinkBasedOnPermissions}
      </>
    );
  }

  if (isCanceledAndExpired) {
    text = (
      <>
        {t('subCanceledAndExpired')} {updatedNowLinkBasedOnPermissions}
      </>
    );
  }

  if (!text) return null; // play it safe

  return (
    <div className={styles.wrapper}>
      <span>{text}</span>
    </div>
  );
};

export default BlackStrip;
