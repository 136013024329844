import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';

import { MessageType } from '../../../components/UI/snackbar/providers/SnackBarProvider';

export default function useAssignContact() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const assign = async ({
    updateCallback,
    entityId,
    teamMemberEmail,
  }: {
    updateCallback: (entityId: string, teamMemberEmail?: string) => Promise<void>;
    entityId: string;
    teamMemberEmail?: string;
  }) => {
    try {
      await updateCallback(entityId, teamMemberEmail);
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({
        message,
        variant: MessageType.Error,
      });
    }
  };

  return assign;
}
