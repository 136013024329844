import React from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

import { MessageType } from '../../../../components/UI/snackbar/providers/SnackBarProvider';
import { useAppDispatch } from '../../../../app/hooks';
import { patchTaskOptimistic } from '../../../../slices/tasksSlice/tasksSlice';

import styles from './ImportanceSwitcher.module.css';
import TaskEntity from 'src/db/entities/task/TaskEntity';

export default function ImportanceSwitcher({
  id,
  important,
  disabled = false,
  onUpdateStart,
  onUpdateEnd,
  className,
}: {
  id: TaskEntity['id'];
  important: TaskEntity['important'];
  disabled?: boolean;
  onUpdateStart?: () => void;
  onUpdateEnd?: () => void;
  className?: string;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    try {
      if (onUpdateStart) {
        onUpdateStart();
      }

      await dispatch(
        patchTaskOptimistic({ id, data: { important: !important, updatedAt: Date.now() } })
      ).unwrap();
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({ message, variant: MessageType.Error });
    } finally {
      if (onUpdateEnd) {
        onUpdateEnd();
      }
    }
  };

  return (
    <div className={clsx(className, styles.wrapper)}>
      <button disabled={disabled} type="button" onClick={handleClick} className={styles.button}>
        {important ? (
          <StarIcon className={styles.isImportant} />
        ) : (
          <StarBorderIcon className={styles.isNotImportant} />
        )}
      </button>
    </div>
  );
}
