import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

import { selectIsAdminOrOwner } from 'slices/workspacesSlice';
import { useAppSelector } from 'src/app/hooks';
import { appRoutes } from 'src/utils/constants';
import ButtonWhiteBordered from '../../ButtonWhiteBordered';

interface IProps {
  className?: string;
}

const AddTeammembersButton: React.FC<IProps> = ({ className }) => {
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const { t } = useTranslation('common');
  const router = useRouter();

  if (!isAdmin) return null;

  const onClick = () => {
    router.push(appRoutes.TEAMMATES_PAGE);
  };

  return (
    <ButtonWhiteBordered
      variant={'add-person'}
      className={className}
      text={t('invite_teammates_button_text')}
      onClick={onClick}
    />
  );
};

export default AddTeammembersButton;
