import { useTranslation } from 'next-i18next';
import React from 'react';

import NotFoundIcon from '../../../../public/images/quick-search-not-found.svg';
import styles from './styles.module.scss';

const QuickSearchNoResults: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.Wrapper}>
      <NotFoundIcon />
      <span>{t('quick_search_no_results')}</span>
    </div>
  );
};

export default QuickSearchNoResults;
