import { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import Tooltip from 'components/UI/Tooltip';
import MembersDialog from 'components/UI/Dialogs/MembersDialog';
import styles from './index.module.scss';
type Avatar = {
  email: string;
  fullName: string | null;
  thumbnail: string | null;
};
const AvatarsFilter = ({
  maxItems = 7,
  items,
  onChange,
}: {
  items: Avatar[];
  maxItems?: number;
  onChange: (string) => void;
}) => {
  const avatarItems = [...items];
  const dialogItems = avatarItems.splice(maxItems);
  const defaultSelectedItemsObj = avatarItems.reduce((acc, item) => {
    acc[item.email] = false;
    return acc;
  }, {});
  const [selectedTeammatesFromAvatar, setSelectedTeammatesFromAvatar] =
    useState(defaultSelectedItemsObj);
  const [selectedTeammatesFromDialog, setSelectedTeammatesFromDialog] = useState([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleCloseDialog = () => {
    setAnchorEl(null);
  };
  const handleOpenDialog = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (email: string) => {
    const filteredMembersMap = {
      ...selectedTeammatesFromAvatar,
      [email]: !selectedTeammatesFromAvatar[email],
    };
    const filteredMembersArr = [];
    for (const key in filteredMembersMap) {
      filteredMembersMap[key] && filteredMembersArr.push(key);
    }
    setSelectedTeammatesFromAvatar(filteredMembersMap);
    onChange([...selectedTeammatesFromDialog, ...filteredMembersArr]);
  };
  const handleChangeDialog = ({ type, email }) => {
    const newSelectedMembers =
      type === 'add'
        ? [...selectedTeammatesFromDialog, email]
        : selectedTeammatesFromDialog.filter((teammate) => teammate !== email);
    setSelectedTeammatesFromDialog(newSelectedMembers);
    onChange([...newSelectedMembers, ...Object.keys(selectedTeammatesFromAvatar)]);
  };
  return (
    <div className={styles.wrapper}>
      {avatarItems.map(({ email, fullName, thumbnail }) => (
        <Tooltip key={email} title={fullName || email} placement={'bottom'}>
          <button
            onClick={() => handleSelect(email)}
            className={clsx({
              [styles.avatar]: true,
              [styles.selectedAvatar]: selectedTeammatesFromAvatar[email],
            })}
          >
            <Avatar
              className={styles.avatarImage}
              key={email}
              alt={fullName || email}
              src={thumbnail}
            >
              {fullName?.[0] || email?.[0]}
            </Avatar>
          </button>
        </Tooltip>
      ))}
      {dialogItems.length ? (
        <>
          <button className={styles.dialogButton} onClick={handleOpenDialog}>
            +{dialogItems.length}
          </button>
          <MembersDialog
            members={dialogItems}
            selected={selectedTeammatesFromDialog}
            onClose={handleCloseDialog}
            anchorEl={anchorEl}
            onChange={handleChangeDialog}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default AvatarsFilter;
