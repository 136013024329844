import clsx from 'clsx';

import { useFormat } from '../../../../lib/date-fns';

import NotificationIcon from 'public/images/icons/notification-small.svg';

import styles from './TimeBadge.module.css';
import TaskEntity from 'src/db/entities/task/TaskEntity';

export default function ReminderBadge({
  reminder,
  className,
}: {
  reminder: TaskEntity['reminder'];
  className?: string;
}) {
  const format = useFormat();

  if (!reminder) {
    return null;
  }

  const formatted = format(reminder.date, 'iii d MMM HH:mm');

  return (
    <div className={clsx(styles.wrapper, className)}>
      <NotificationIcon className={styles.icon} />
      {formatted}
    </div>
  );
}
